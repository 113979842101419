<template>
  <div class="c-company-create">
    <div class="company-window">
      <n-card class="company-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="company-info">
            <n-items>
              <n-input title="Название компании" v-bind="$form.input('title')"></n-input>
              <n-input title="Бин" v-bind="$form.input('bin')"></n-input>
              <n-input title="Лимит проценты" v-bind="$form.input('limit')"></n-input>
              <n-input title="День выплаты заработной платы" v-bind="$form.input('salaryDate')"></n-input>
              <n-select :data="commissionTypes" title="Удержание комиссии" :value.sync="commissionType"></n-select>
              <n-select :data="salaryTypes" title="Тип выплачиваемой заработной платы" :value.sync="salaryType"></n-select>
              <n-input title="Комиссия на переводы проценты" v-bind="$form.input('commissionPercent')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCompanyCreate',
  data() {
    return {
      commissionTypes: [
        { name: 'company', title: 'С компании', value: true, },
        { name: 'user', title: 'С работников', value: false, },
      ],
      salaryTypes: [
        { name: 'netto', title: 'Нетто', value: true, },
        { name: 'brutto', title: 'Брутто (Гросс)', value: false, },
      ],
      commissionType: { name: 'company', title: 'С компании', value: true, },
      salaryType: { name: 'brutto', title: 'Брутто (Гросс)', value: false, },
      limit: 50,
      commissionPercent: 5,
    }
  },
  created() {
    this.$form.init({
      title: '',
      bin: '',
      limit: '50',
      salaryDate: '10',
      commissionPercent: '5',
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      const data = {
        title: this.$form.get('title'),
        bin: this.$form.get('bin'),
        limit: this.$form.get('limit'),
        salaryDate: this.$form.get('salaryDate'),
        selfCommission: this.commissionType.value,
        isSalaryNetto: this.salaryType.value,
        commissionPercent: this.$form.get('commissionPercent'),
      }
      this.$var('load', true)
      $api.companies.create(data).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-company-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .company-card {
    min-width: 700px;
    min-height: 300px;
    max-width: 80vw;
    display: flex;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
