export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/companies', name: 'companies', title: 'Компании', icon: 'genderless', component: () => import('pages/companies/Index'), },
      { path: '/company/:id', name: 'company', title: 'Компания', icon: 'genderless', component: () => import('pages/company/Index'), },
    ], },
  ], },
]
